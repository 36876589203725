import React, { Component } from "react";
import {
  Menu,
  MenuItem,
  Image,
  Container,
  Button,
  MenuMenu,
  Segment,
  Header,
} from "semantic-ui-react";
import Logo from "../assets/ico.png";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = { sticky: false };
  }

  //handlers

  handleOnScroll() {
    const nbar = $("#nbar")[0];
    const position = nbar.getBoundingClientRect();

    if (this.state.sticky && window.scrollY == 0) {
      this.setState({
        sticky: false,
      });
    } else if (!this.state.sticky && position.bottom < 0) {
      this.setState({
        sticky: true,
      });
    }
  }

  //lifecycle

  componentDidMount() {
    window.addEventListener("scroll", this.handleOnScroll.bind(this));
  }

  render() {
    let fixed = {};
    if (this.state.sticky) fixed = { fixed: "top" };

    return (
      <div>
        <Menu borderless id="nbar" {...fixed}>
          <Container>
            <MenuItem>
              <Image src={Logo} size="mini" />
            </MenuItem>
            <MenuMenu position="right">
              <MenuItem>
                <Button primary>logout</Button>
              </MenuItem>
            </MenuMenu>
          </Container>
        </Menu>
      </div>
    );
  }
}

export default NavBar;
