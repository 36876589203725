import React from "react";
import ReactDom from "react-dom";
import { Link } from "react-router-dom";
import { Container, Header, Image, Segment, Button } from "semantic-ui-react";
import Logo from "../assets/logo.svg";
import conAppData from "../data/connectedApp.json";

class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = { auth: props.auth };

    //binds
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    let url = conAppData.URL;
    let params = "";

    Object.entries(conAppData.params).forEach(([k, v]) => {
      params += (params != "" ? "&" : "?") + k + "=" + v;
    });

    window.location.href = url + params;
  }

  render() {
    return (
      <div className="splash">
        <Container>
          <Segment basic textAlign="right">
            <Link to="/about">about</Link>
          </Segment>
          <Segment basic textAlign="center">
            <Image src={Logo} centered verticalAlign="middle" />
            <Header inverted as="h2" size="huge">
              Be confident, know your org!
            </Header>
            <Button inverted color="blue" onClick={this.handleClick}>
              Explore your Org
            </Button>
          </Segment>
        </Container>
      </div>
    );
  }
}

export default Welcome;
