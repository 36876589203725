import React from "react";
import SObjectFilter from "./SObjectFilter.js";
import SObjectList from "./SObjectList";
import { Divider, Container, Segment } from "semantic-ui-react";

class SObjectFilteredList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iniList: null,
      filtered: [],
      filter: {
        input: "",
        options: {},
      },
    };

    //ini filter options
    this.state.filter.options = { standard: true, custom: true, system: false };

    //binds
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  /* helpers */

  updateFilteredList() {
    let filtered = [];

    if (this.state.iniList) {
      let iniList = this.state.iniList;
      let filter = this.state.filter;

      filtered = iniList.filter(function (sobj) {
        let keep = false;
        let isSystem = sobj.keyPrefix == null;

        //custom
        if (filter.options.custom && sobj.custom) keep = true;
        //standard
        if (filter.options.standard && !sobj.custom && !isSystem) keep = true;
        //system
        if (filter.options.system && isSystem) keep = true;

        return keep;
      });
    } else filtered = null;

    return filtered;
  }

  /* handlers */

  handleFilterChange(inputValue) {
    this.setState({
      filter: {
        input: inputValue,
        options: this.state.filter.options,
      },
    });
  }

  handleOptionChange(newOptions) {
    this.setState({
      filter: {
        input: this.state.filter.input,
        options: newOptions,
      },
    });
  }

  /* lifecycle */

  componentDidMount() {
    console.log("IN componentDidMount");
    try {
      const hash = document.cookie
        .split(";")
        .find((c) => c.trim().startsWith("sf_hash"))
        .split("#")[1];

      const auth = new URLSearchParams(hash);
      const uri = auth.get("instance_url") + "/services/data/v46.0/sobjects";

      let header = new Headers();
      header.append("Authorization", "Bearer " + auth.get("access_token"));

      console.log(uri);

      fetch(uri, {
        method: "GET",
        headers: header,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.setState({
            iniList: data.sobjects,
          });
        });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    let filtered = this.updateFilteredList();

    return (
      <Container>
        <Segment basic>
          <SObjectFilter
            filter={this.state.filter}
            onFilterChange={this.handleFilterChange}
            onOptionChange={this.handleOptionChange}
          />
          <Divider />
          <SObjectList
            sobjList={filtered}
            inputFilter={this.state.filter.input}
          />
          <Divider />
        </Segment>
      </Container>
    );
  }
}

export default SObjectFilteredList;
