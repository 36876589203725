import React from "react";
import { Placeholder, Label, List } from "semantic-ui-react";

class SObjectListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sobj: props.sobj,
    };
  }

  renderPlaceholder() {
    return (
      <Placeholder>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    );
  }

  renderItem() {
    let sobj = this.state.sobj;

    //key ribbon
    let ribbon = (
      <Label ribbon color="orange">
        {sobj.keyPrefix}
      </Label>
    );
    //labels7
    let custom = (
      <Label color="blue">
        <i className="fas fa-wrench"></i>
        <Label.Detail>custom</Label.Detail>
      </Label>
    );
    let customSetting = (
      <Label color="teal">
        <i className="fas fa-pencil-ruler"></i>
        <Label.Detail>custom setting</Label.Detail>
      </Label>
    );

    return (
      <List.Item>
        <List.Content floated="right">
          {sobj.customSetting ? customSetting : sobj.custom ? custom : null}
        </List.Content>
        {sobj.keyPrefix ? ribbon : null}
        <List.Content>
          <List.Header>{sobj.label}</List.Header>
          <List.Description>{sobj.name}</List.Description>
        </List.Content>
      </List.Item>
    );
  }

  renderItemOld() {
    let sobj = this.state.sobj;

    //key ribbon
    let ribbon = <div className="ui orange ribbon label">{sobj.keyPrefix}</div>;
    //labels7
    let custom = (
      <div className="ui blue label">
        <i className="fas fa-wrench"></i>
        <div className="detail">custom</div>
      </div>
    );
    let customSetting = (
      <div className="ui teal label">
        <i className="fas fa-pencil-ruler"></i>
        <div className="detail">custom setting</div>
      </div>
    );

    return (
      <div className="item">
        <div className="right floated content">
          {sobj.customSetting ? customSetting : sobj.custom ? custom : null}
        </div>
        {sobj.keyPrefix ? ribbon : null}
        <div className="content">
          <div className="header">{sobj.label}</div>
          {sobj.name}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.sobj != null) return this.renderItem();
    else return this.renderPlaceholder();
  }
}

export default SObjectListItem;
