import React from "react";
import { List } from "semantic-ui-react";
import SObjectListItem from "./SObjectListItem";

class SObjectItem extends React.Component {
  constructor(props) {
    super(props);
  }

  renderPlaceholder() {
    console.log("MG> IN renderPlaceholder");
    let items = [];

    for (var i = 0; i < 3; i++) {
      items.push(<SObjectListItem key={i} sobj={null} />);
    }

    return items;
  }

  renderItems() {
    let items = [];

    let sobjList = this.props.sobjList.filter(
      (sobj) =>
        sobj.label.toLowerCase().includes(this.props.inputFilter) ||
        sobj.name.toLowerCase().includes(this.props.inputFilter) ||
        sobj.keyPrefix.toLowerCase().startsWith(this.props.inputFilter)
    );

    {
      sobjList.map((sobj) =>
        items.push(<SObjectListItem key={sobj.name} sobj={sobj} />)
      );
    }

    return items;
  }

  render() {
    let items = this.props.sobjList
      ? this.renderItems()
      : this.renderPlaceholder();

    return (
      <List divided relaxed>
        {items}
      </List>
    );
  }
}

export default SObjectItem;
