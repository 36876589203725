import React from "react";
import { Redirect } from "react-router-dom";
import { Button, BreadcrumbDivider } from "semantic-ui-react";
import conAppData from "../data/connectedApp.json";

class OAuth2Flow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    console.log("MG> in DeviredFromProps!");

    let loc = window.location.toString();

    if (loc.includes("#")) {
      let hash = decodeURIComponent(loc).split("#")[1];
      let params = new URLSearchParams(hash);

      console.log(params);

      if (params.has("access_token")) {
        let cookie = "sf_hash=#" + hash + "#";

        console.log(props);

        document.cookie = cookie;
        window.location.replace("/");
      }
    }

    return null;
  }

  render() {
    return null;
  }
}

export default OAuth2Flow;
