import React from "react";
import { Form } from "semantic-ui-react";

class SObjectFilter extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   filter: "",
    //   options: { standard: true, custom: true, system: false },
    // };

    //event binding
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  handleFilterChange(event) {
    this.props.onFilterChange(event.target.value);

    // this.setState({
    //   filter: event.target.value,
    // });
  }

  handleOptionChange(event, target) {
    let newOptions = {};

    for (const prop in this.props.filter.options) {
      if (target.name == prop) newOptions[prop] = target.checked;
      else newOptions[prop] = this.props.filter.options[prop];
    }

    this.props.onOptionChange(newOptions);

    // this.setState({
    //   options: newOptions,
    // });
  }

  render() {
    let options = this.props.filter.options;

    return (
      <Form>
        <Form.Input
          fluid
          icon="search"
          placeholder="filter objects..."
          onChange={this.handleFilterChange}
        />
        <Form.Group>
          <Form.Checkbox
            toggle
            name="standard"
            label="standard"
            checked={options.standard}
            onChange={this.handleOptionChange}
          />
          <Form.Checkbox
            toggle
            name="custom"
            label="custom"
            checked={options.custom}
            onChange={this.handleOptionChange}
          />
          <Form.Checkbox
            toggle
            name="system"
            label="system"
            checked={options.system}
            onChange={this.handleOptionChange}
          />
        </Form.Group>
      </Form>
    );
  }
}

export default SObjectFilter;
