import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Header,
  Segment,
  Card,
  Image,
} from "semantic-ui-react";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Container>
        <Segment basic>
          <Header as="h2">Explore your ORG</Header>
        </Segment>

        <Grid columns="4">
          <Grid.Row>
            <Grid.Column>
              <Card>
                <Link to="/limits">
                  <Image
                    as="i"
                    className="fas fa-tachometer-alt fa-8x"
                    wrapped
                    ui={false}
                    style={{ padding: "0.5em .333em", textAlign: "center" }}
                  />
                </Link>
                <Card.Content>
                  <Card.Header>Limits</Card.Header>
                  <Card.Description>Limits, yeah! Those...</Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card>
                <Link to="/limits" />
                <Image
                  as="i"
                  className="fas fa-cube fa-8x"
                  wrapped
                  ui={false}
                  style={{ padding: "0.5em .333em", textAlign: "center" }}
                />
                <Card.Content>
                  <Card.Header>Objects</Card.Header>
                  <Card.Description>
                    Object definitions, field information, child
                    relationships... All you need to know.
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default Dashboard;
