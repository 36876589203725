import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Container, Header } from "semantic-ui-react";
import Welcome from "./Welcome";
import OAuth from "./OAuth2Flow";
import NavBar from "./NavBar";
import Dashboard from "./Dashboard";
import SObjectFilteredList from "./SObjectFilteredList";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: this.retrieveAuthInfo(),
    };
  }

  //helpers
  retrieveAuthInfo() {
    let authInfo = {};

    try {
      const auth = new URLSearchParams(
        document.cookie
          .split(";")
          .find((c) => c.trim().startsWith("sf_hash"))
          .split("#")[1]
      );

      authInfo.access_token = auth.get("access_token");
    } catch (e) {
      console.warn(e);
    }

    return authInfo;
  }

  //handlers

  //lifecycle

  render() {
    let redirToHome = <Redirect to="/welcome" />;
    let redirToDash = <Redirect to="/dashboard" />;

    return (
      <Router>
        <Switch>
          <Route path="/limits">
            {!this.state.auth.access_token ? redirToHome : null}
            <NavBar />
            <SObjectFilteredList />
          </Route>
          <Route path="/dashboard">
            {!this.state.auth.access_token ? redirToHome : null}
            <NavBar />
            <Dashboard />
          </Route>
          <Route path="/oauth-cb">
            <OAuth />
          </Route>
          <Route path="/">
            {this.state.auth.access_token ? redirToDash : null}
            <Welcome />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
